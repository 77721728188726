<template>
  <InfoContainer info="guardianConsent"></InfoContainer>
</template>

<script>
  import InfoContainer from '@/components/info/InfoContainer';

  export default {
    name: 'GuardianConsent',
    components: {
      InfoContainer
    }
  };
</script>

<style lang="scss"></style>
